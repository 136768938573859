@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prosto+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --FontFamily_Figtree: 'Figtree', sans-serif;
  --FontFamily_ProstoOne: "Prosto One", sans-serif;
  --FontFamily_RobotoCondensed: "Roboto Condensed", sans-serif;
  --mainColor: #FAE069;
  --secondaryColor: #162238;
  --darkgreenColor: #0D210C;

  --whiteColor: #ffffff;
  --blackColor: #000000;
  --pragraphColor: #505050;
  --orangeColor: #ED5500;
  --redColor: #DE001B;

  --Color1: #F3F3F3;
  --Color2: #727B92;
}

.FontFamilyFigtree {
  font-family: var(--FontFamily_Figtree);
}

.FontFamilyProstoOne {
  font-family: var(--FontFamily_ProstoOne);
}

.FontFamilyRobotoCondensed {
  font-family: var(--FontFamily_RobotoCondensed) !important;
}

.mainText {
  color: var(--mainColor);
}

.mainBG {
  background-color: var(--mainColor) !important;
}

.secondaryText {
  color: var(--secondaryColor) !important;
}

.secondaryBG {
  background-color: var(--secondaryColor);
}

.whiteText {
  color: var(--whiteColor) !important;
}

.whiteBG {
  background-color: var(--whiteColor) !important;
}

.blackText {
  color: var(--blackColor);
}

.blackBG {
  background-color: var(--blackColor);
}

.pragraphText {
  color: var(--pragraphColor);
}

.pragraphBG {
  background-color: var(--pragraphColor);
}

.darkgreenText {
  color: var(--darkgreenColor);
}

.darkgreenBG {
  background-color: var(--darkgreenColor);
}

.orangeText {
  color: var(--orangeColor) !important;
}

.orangeBG {
  background-color: var(--orangeColor) !important;
}

.redText {
  color: var(--redColor) !important;
}

.redBG {
  background-color: var(--redColor) !important;
}

.color1Text {
  color: var(--Color1);
}

.color1BG {
  background-color: var(--Color1);
}

.color2Text {
  color: var(--Color2);
}

.color2BG {
  background-color: var(--Color2);
}

.dropShadow {
  -webkit-box-shadow: 0px 6px 52.7px 0px rgba(22, 34, 56, 0.25);
  -moz-box-shadow: 0px 6px 52.7px 0px rgba(22, 34, 56, 0.25);
  box-shadow: 0px 6px 52.7px 0px rgba(22, 34, 56, 0.25);
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
  font-family: var(--FontFamily_Figtree);
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
}

body {
  margin: 0;
  color: var(--pragraphColor);
  font-family: var(--FontFamily_Figtree);
  line-height: 28px;
  font-size: 16px;
  overflow: overlay;
  /* min-height: 100vh; */
}

::selection {
  background: transparent;
  color: var(--mainColor);
}

p {
  padding: 0;
  font-family: var(--FontFamily_Figtree);
  font-size: 16px;
  color: var(--pragraphColor);
}

@media(max-width:991px) {
  p {
    font-size: 14px;
  }
}

.bgimage {
  background-image: url(./images/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -208px 175px;
}

.pb-7 {
  padding-bottom: 7rem !important;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' linecap='round' linejoin='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}


.login-card-bg {
  background-image: url("./images/login-card.png");
  background-size: cover;
  background-position: center;
  position: relative;
  color: var(--whiteColor);
  /* overflow: hidden; */
}

.regidter-card-bg {
  background-image: url("./images/register.png");
  background-size: cover;
  background-position: center;
  position: relative;
  color: var(--whiteColor);
}

.login-card-bg::after,
.regidter-card-bg::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: rgb(22, 34, 56);
  background: linear-gradient(180deg, rgba(22, 34, 56, 1) 0%, rgba(82, 76, 117, 0.15730042016806722) 50%);
  width: 100%;
  height: 100%;
}
.login-card-before{
  position: relative;
}
.login-card-before::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: -1;
  width: 300px;
  background-image: url(./images/airoplane-left.svg);
  background-repeat: no-repeat;
  background-position: top left;
}
.login-card-after{
  position: relative;
}
.login-card-after::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 300px;
  background-image: url(./images/airoplane-right.svg);
  background-repeat: no-repeat;
  background-position: top right;
  z-index: -1;
}

@media(min-width:1199px) {
  .login-card-after::after {
    right: -169px;
    top: -70px;
  }
  .login-card-before::before {
    left: -170px;
    top: -70px;
  }
}

@media(max-width:1199px) {
  .login-card-after::after {
    display: none;
  }
}

.welcometext {
  position: relative;
  z-index: 1;
  transform: translate(0px, 25px);
  text-align: center;
}

.bgimage .card {
  border-radius: 0px !important;
  /* overflow: hidden; */
}

.fab {
  font-size: 1.5rem;
  margin: 0 0.5rem;
  color: var(--blackColor);
}

.row.g-0 {
  flex-wrap: nowrap;
}

.d-title {
  font-family: var(--FontFamily_ProstoOne);
  font-size: 45px;
  line-height: 45px;
  color: var(--secondaryColor);
}
.btn:focus{
  border: none;
  box-shadow: none;
}
.btn-outline-primary {
  color: var(--secondaryColor);
  border-color: var(--secondaryColor);
}

.btn-outline-primary:hover {
  background-color: var(--secondaryColor);
  border-color: var(--secondaryColor);
}

/* header */
.header {
  background: transparent;
  /* position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; */
}

.header.navbar-area-fixed {
  background: var(--secondaryColor);
  -webkit-box-shadow: 0px 4px 6px 0px, #0c002e;
  box-shadow: 0px 4px 6px 0px, #0c002e;
  padding: 0.7rem 0rem;
  transition: all 0.6s ease;
}

.header.navbar-area-fixed .blogsearch {
  background-color: var(--whiteColor);
}

.header-bg {
  position: relative;
  background-color: var(--secondaryColor);
}

.navbar-expand-lg .navbar-nav .nav-link {
  font-size: 16px;
  font-weight: 400;
  color: var(--whiteColor);
  line-height: 19.2px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  padding: 0;
  /* transform: translate(0px, 21%); */
  border-radius: 0;
  border: solid 1px var(--mainColor);
}

.navbar-expand-lg .navbar-nav .dropdown-menu.view {
  background-color: var(--secondaryColor);
  border-radius: 10px;
  padding: 10px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item:hover,
.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item:focus {
  background-color: var(--mainColor);
  color: var(--secondaryColor);
}

.navbar-expand-lg .navbar-nav .form-select {
  background-image: url(./images/arrow-down-white.svg);
  background-size: 20%;
  max-width: 110px;
  min-width: 110px;
}

.navbar-expand-lg .navbar-nav .form-select:focus {
  box-shadow: none;
}

.navbar-expand-lg .navbar-nav .btn-signin {
  background-color: var(--mainColor);
  border-radius: 100px;
  border: solid 2px transparent;
  /* box-shadow: 2px 11px 24px 0px var(--mainColor); */
  -webkit-box-shadow: 2px 11px 24px 0px rgba(252, 203, 96, 0.5);
  -moz-box-shadow: 2px 11px 24px 0px rgba(252, 203, 96, 0.5);
  box-shadow: 2px 11px 24px 0px rgba(252, 203, 96, 0.5);
  display: flex;
  align-items: center;
  padding: 0;
  transition: all .6s ease;
  min-height: 35px;
  max-height: 35px;
  justify-content: center;
  font-size: 16px;
  max-width: 114px;
  margin: 0;
  min-width: 114px;
  color: var(--blackColor);
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 0px;
}

.navbar-expand-lg .navbar-nav .btn-signin:hover {
  background-color: transparent;
  /* box-shadow: 0px 4px 11px 0px var(--secondaryColor); */
  color: var(--mainColor);
  border-color: var(--mainColor);
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 56px;
  right: auto;
  left: -50px;
}
.dropend.dropenddropend .form-control{
  border: solid 1px #f1f1f1 !important;
  height: 35px !important;
  border-radius: 4px !important;
  width: 100%;

}
.dropenddropend .dropdown-menu{
  transform:translate3d(0px, 40px, 10px) !important;
}
.react-datepicker-wrapper{
  width: 100%;
}

/* @media(max-width:420px){
  .navbar-expand-lg .navbar-nav .nav-link{
    font-size: 12px;
  }
  .navbar-expand-lg .navbar-nav .btn-signin{
    font-size: 12px;
    max-width: 109px;
    min-width: 100px;
  }
  .navbar-expand-lg .navbar-nav .form-select{
    font-size: 12px;
  }
} */


/* banner */
.banner {
  border-radius: 0px 0px 48px 48px;
  background-image: url(./images/banner_01.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0;
  height: calc(100vh - 20vh);
  position: relative;
  color: var(--whiteColor);
}

.banner.image_01 {
  background-image: url(./images/bg-10.jpg);
}
.travelbanner.image_01 {
  background-image: url(./images/travelagencies.jpg);
}
.flightbooking.image_01 {
  background-image: url(./images/flight-book.png);
}

.banner::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  border-radius: 0px 0px 48px 48px;
  /* background: rgba(22, 34, 56, 0.2); */
  background: rgb(22, 34, 56);
  background: linear-gradient(180deg, rgba(22, 34, 56, 1) 0%, rgba(22, 34, 56, 0.6362920168067228) 50%);
}

.banner h1 {
  font-size: 60px;
  color: var(--whiteColor);
  font-family: var(--FontFamily_ProstoOne);
  font-weight: 400;
  text-transform: uppercase;
  line-height: 65px;
}

.banner h1 span {
  color: var(--mainColor);
}

.banner .booknow {
  background-color: var(--mainColor);
  border-radius: 100px;
  /* box-shadow: 2px 11px 24px 0px var(--mainColor); */
  /* -webkit-box-shadow: 2px 11px 24px 0px rgba(252, 203, 96, 0.5); */
  /* -moz-box-shadow: 2px 11px 24px 0px rgba(252, 203, 96, 0.5); */
  /* box-shadow: 2px 11px 24px 0px rgba(252, 203, 96, 0.5); */
  display: flex;
  align-items: center;
  padding: 0;
  min-height: 50px;
  max-height: 50px;
  justify-content: center;
  font-size: 16px;
  max-width: 175px;
  margin: 0;
  min-width: 175px;
  border: solid 2px transparent;
  transition: all .6s ease;
  color: var(--blackColor) !important;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 0px;
}

.banner .booknow:hover {
  background-color: transparent;
  /* box-shadow: 0px 4px 11px 0px var(--secondaryColor); */
  color: var(--mainColor) !important;
  border-color: var(--mainColor);
}

@media(max-width:1199px) {
  .banner h1 {
    font-size: 40px;
    line-height: 45px;
  }
}

@media(max-width:768px) {
  .banner h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .banner {
    border-radius: 0;
  }
}


/* servicesCads */
.servicesCads .card.cardColo {
  background: transparent;
  max-width: 90%;
  margin: 0 auto;
}

/* vehicleUl */
.vehicleUl .card h4.title {
  color: var(--secondaryColor);
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
}

.vehicleUl .card p.bottomText {
  color: var(--pragraphColor);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400px;
}

.vehicleUl .card .price {
  font-size: 40px;
  line-height: 48px;
  font-weight: bold;
  color: var(--darkgreenColor);
}

.vehicleUl .card .bookNow-btn {
  height: 50px;
  font-weight: bold;
  min-width: 175px;
  max-width: 175px;
  transition: all .6s ease;
  border: solid 2px transparent;
  border-radius: 100px;
  background-color: var(--secondaryColor);
  color: var(--mainColor);
}

.vehicleUl .card .bookNow-btn:hover {
  background-color: var(--whiteColor);
  color: var(--darkgreenColor);
  border-color: var(--darkgreenColor);
}

.vehicleUl .paymentFrom .form-label {
  font-size: 16px;
  font-weight: 400;
  color: #505050;
}

.vehicleUl .paymentFrom .form-control {
  border-radius: 8px;
  background-color: var(--Color1);
  height: 50px;
  border: none;
}

.vehicleUl .paymentFrom .form-control.mastercards {
  background-image: url(./images/mastercards.png);
  background-repeat: no-repeat;
  background-position: right center;
}

.vehicleUl .paymentFrom .form-control:focus {
  box-shadow: none;
}

.vehicleUl .input-group-text {
  border: none;
  padding: 0;
}

@media(min-width:991px) {
  .vehicleUl .roline::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 1.5px;
    height: calc(100% - 10%);
    z-index: 0;
    border: dashed 1.5px var(--pragraphColor);
  }
}

@media(max-width:1199px) {
  .vehicleUl .card .price {
    font-size: 26px;
  }

  .vehicleUl .card h4.title {
    font-size: 22px;
  }
}

@media(max-width:991px) {
  .vehicleUl .card .price {
    font-size: 20px;
  }
}

/* blog Head */
.allView-btn {
  height: 50px;
  font-weight: bold;
  min-width: 175px;
  max-width: 175px;
  transition: all .6s ease;
  border: solid 2px transparent;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondaryColor);
  color: var(--mainColor);
}

.allView-btn:hover img {
  filter: brightness(0);
}

.allView-btn:hover {
  background-color: var(--whiteColor);
  color: var(--darkgreenColor);
  border-color: var(--darkgreenColor);
}
/* @media(max-width:991px) {
  .allView-btn {
    min-width: 120px;
    max-width: 120px;
    height: 35px;
  }
} */

/* blog allViewCancel */
.allViewCancel-btn {
  height: 50px;
  font-weight: bold;
  min-width: 175px;
  max-width: 175px;
  transition: all .6s ease;
  border: solid 2px transparent;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--redColor);
  color: var(--whiteColor);
}

.allViewCancel-btn:hover img {
  filter: brightness(0);
}

.allViewCancel-btn:hover {
  background-color: transparent;
  color: var(--redColor);
  border-color: var(--redColor);
}
/* @media(max-width:991px) {
  .allViewCancel-btn {
    min-width: 120px;
    max-width: 120px;
    height: 35px;
  }
} */
/* blog Head */
.border-btn {
  height: 50px;
  font-weight: bold;
  min-width: 175px;
  max-width: 175px;
  transition: all .6s ease;
  border: solid 2px var(--secondaryColor);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--whiteColor);
  color: var(--secondaryColor);
}

.border-btn:hover {
  background-color: var(--secondaryColor);
  color: var(--mainColor);
  border-color: var(--secondaryColor);
}
/* @media(max-width:991px) {
  .border-btn{
    min-width: 150px;
    max-width: 150px;
    height: 35px;  
  }
} */

/* blog Head */
.blogHead {
  border-radius: 0px 0px 48px 48px;
  background-image: url(./images/banner_02.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0;
  height: calc(100vh - 20vh);
  position: relative;
  color: var(--whiteColor);
}

.blogHead::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  border-radius: 0px 0px 48px 48px;
  background: rgba(22, 34, 56, 0.2);
}

.blogHead h1 {
  font-size: 60px;
  color: var(--whiteColor);
  font-family: var(--FontFamily_ProstoOne);
  font-weight: 400;
  text-transform: uppercase;
  line-height: 65px;
}

.blogHead h1 span {
  color: var(--mainColor);
}

@media(max-width:1199px) {
  .blogHead h1 {
    font-size: 40px;
    line-height: 45px;
  }
}

@media(max-width:768px) {
  .blogHead h1 {
    font-size: 30px;
    line-height: 35px;
  }
}

/* howItWork */
.howItWork {
  position: relative;
  padding: 160px 0 60px 0;
}

.howItWork h5.howItWorkTitle {
  font-size: 40px;
  line-height: 48px;
  color: var(--secondaryColor);
  font-weight: 400;
  font-family: var(--FontFamily_ProstoOne);
  text-transform: uppercase;
}

@media(max-width:991px) {
  .howItWork {
    padding: 60px 0 60px 0;
  }

  .howItWork h5.howItWorkTitle {
    font-size: 28px;
  }
}

/* blogPageWork */
.allTile {
  font-size: 40px;
  line-height: 48px;
  color: var(--secondaryColor);
  font-weight: 400;
  font-family: var(--FontFamily_ProstoOne);
  text-transform: uppercase;
}

@media(max-width:1199px) {
  .allTile {
    font-size: 30px;
    line-height: 38px;
  }
}

@media(max-width:991px) {
  .allTile {
    font-size: 20px;
    line-height: 28px;
  }
}


/* blogPageWork */
.blogPageWork {
  position: relative;
  background-color: #f3f3f3;
}

.blogPageWork .card {
  border: none;
  border-radius: 24px;
  -webkit-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
}

.blogPageWork .card .cardImg {
  overflow: hidden;
  height: 280px;
  border-radius: 24px 24px 0 0;
}

.blogPageWork .card .cardImg img {
  width: 100%;
  transition: all .6s ease;
}

.blogPageWork .card:hover .cardImg img {
  transform: scale(1.1);
}

.blogPageWork .card small {
  font-weight: 700;
  color: var(--pragraphColor);
  font-size: 14px;
}

.blogPageWork .card .title {
  font-size: 24px;
  line-height: 28px;
  transition: all .6s ease;
  font-weight: 600;
  color: var(--secondaryColor);
}

.blogPageWork .card p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--pragraphColor);
  /* white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; */
}

.blogPageWork .card:hover .title {
  color: var(--darkgreenColor);
}

.blogPageWork .card .readmore {
  font-weight: 600;
  font-size: 14px;
  color: var(--pragraphColor);
  text-transform: uppercase;
}

.blogPageWork .card:hover .readmore {
  color: var(--secondaryColor);
}

/* info_wrap */
.info_wrap {
  position: relative;
  /* height: 100vh; */
}

.info_wrap .content h4.title {
  color: var(--secondaryColor);
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: var(--FontFamily_RobotoCondensed);
  line-height: 25px;
  position: relative;
}

.info_wrap .content h4.title::before {
  content: '';
  position: absolute;
  left: -32px;
  right: auto;
  top: 0;
  z-index: 1;
  border: 8px solid transparent;
  border-bottom-color: var(--darkgreenColor);
}

.info_wrap .content h4.title::after {
  content: '';
  position: absolute;
  left: -32px;
  right: auto;
  bottom: -7px;
  z-index: 1;
  border: 8px solid transparent;
  border-top-color: var(--darkgreenColor);
}

.info_wrap .content h4.title1 {
  color: var(--secondaryColor);
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: var(--FontFamily_RobotoCondensed);
  line-height: 25px;
  position: relative;
}

.info_wrap .content h4.title1::before {
  content: '';
  position: absolute;
  left: auto;
  right: -32px;
  top: 0;
  z-index: 1;
  border: 8px solid transparent;
  border-bottom-color: var(--darkgreenColor);
}

.info_wrap .content h4.title1::after {
  content: '';
  position: absolute;
  left: auto;
  right: -32px;
  bottom: -7px;
  z-index: 1;
  border: 8px solid transparent;
  border-top-color: var(--darkgreenColor);
}

.info_wrap .content p {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  font-family: var(--FontFamily_RobotoCondensed);
}

.info_wrap h2 {
  font-size: 38px;
  color: var(--secondaryColor);
  font-family: var(--FontFamily_ProstoOne);
  font-weight: 400;
  text-transform: uppercase;
  line-height: 45px;
}

.info_wrap.bg_1 {
  background-image: url(./images/bg-1.png), url(./images/bg-2.png), url(./images/bg-6.png);
  background-position: left -70px, right top, right center;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
}

@media(min-width:991px) {
  .info_wrap .row::before {
    content: '';
    position: absolute;
    border: dashed 1px var(--pragraphColor);
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    width: 2px;
    letter-spacing: 4px;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    top: 100px;
  }
}

@media(max-width:991px) {
  .info_wrap.bg_1 {
    background-image: none;
  }

  .info_wrap h2 {
    font-size: 32px;
  }
}

/* full */
.full {
  position: relative;
  background-color: var(--darkgreenColor);
}

.full::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: -300px;
  background-image: url(./images/bg-7.png);
  background-position: left top;
  background-repeat: no-repeat;
}

/* coumn_wrap */
.coumn_wrap {
  position: relative;
}

.coumn_wrap h5 {
  color: var(--whiteColor);
  font-size: 16px;
  font-weight: 700;
  position: relative;
}

.coumn_wrap span {
  font-size: 48px;
  font-weight: 700;
  position: absolute;
  top: -30px;
  left: -30px;
  color: var(--mainColor);
  display: inline-block;
}

.coumn_wrap p {
  color: var(--whiteColor);
  line-height: 24px;
  font-weight: 400;
  font-family: var(--FontFamily_Figtree);
}

@media(max-width:1199px) {
  .coumn_wrap span {
    position: relative;
    top: 0;
    left: 0;
  }
}


/* blogsearch */
.blogsearch.form-control {
  border-radius: 100px;
  background-color: var(--secondaryColor);
  border: none;
  height: 35px;
  max-width: 311px;
  font-size: 14px;
  min-width: 311px;
  color: var(--Color1);
}

.blogsearch.form-control:focus {
  box-shadow: none;
}

.blogsearch.form-control::-webkit-input-placeholder {
  color: #616f78;
  opacity: 1;
}

.blogsearch.form-control::-moz-placeholder {
  color: #616f78;
  opacity: 1;
}

.blogsearch.form-control:-ms-input-placeholder {
  color: #616f78;
  opacity: 1;
}

.blogsearch.form-control::-ms-input-placeholder {
  color: #616f78;
  opacity: 1;
}

.blogsearch.form-control::placeholder {
  color: #616f78;
  opacity: 1;
}

/* FeaturedPost */
.FeaturedPost .card {
  background-color: transparent;
  box-shadow: none;
}

.FeaturedPost .card small {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--pragraphColor);
}

.FeaturedPost .card .title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.FeaturedPost .card p {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--pragraphColor);
}

.FeaturedPost .card .cardImg {
  border-radius: 8px;
  min-height: 182px;
  max-height: 182px;
  display: flex;
  background-position: center center;
  align-items: center;
  justify-content: center;
}

.FeaturedPost .card .cardImg img {
  min-height: 182px;
  max-height: 182px;
  object-fit: cover;
}

/* tabs_wrap */
/* .tabs_wrap .react-tabs {
  background-color: transparent;
  margin: -115px 0 0 0;
  position: relative;
}

.tabs_wrap .react-tabs .react-tabs__tab-list {
  border: none;
  background-color: var(--Color1);
  border-radius: 32px 32px 0 0;
  padding: 20px;
  margin-bottom: 0;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
} */

.tabs_wrap .form-label {
  font-size: 12px;
  color: var(--pragraphColor);
  margin-bottom: 0;
}

.tabs_wrap .form-control {
  border: none;
  font-size: 18px;
  height: 18px;
  padding-left: 0px;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: var(--secondaryColor);
  font-weight: 500;
  background-image: none;
}

.tabs_wrap .css-13cymwt-control {
  border: none;
  font-size: 16px;
  padding-left: 0px;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: var(--secondaryColor) !important;
  font-weight: 500;
  background-image: none;
}

.tabs_wrap .css-t3ipsp-control:hover {
  border-color: none;
}

.tabs_wrap .form-control:focus,
.tabs_wrap .css-13cymwt-control:focus {
  box-shadow: none !important;
}

.tabs_wrap .form-select {
  border: none;
  font-size: 18px;
  padding-left: 0px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  color: var(--secondaryColor);
  font-weight: 500;
  background-image: none;
}

.tabs_wrap .form-select:focus {
  box-shadow: none;
}

.tabs_wrap .react-tabs__tab {
  color: var(--secondaryColor);
  font-family: var(--FontFamily_Figtree);
  font-weight: bold;
  font-size: 16px;
  font-weight: 500;
  border-radius: 100px;
  max-width: 175px;
  min-width: 175px;
  border: none;
}

.tabs_wrap .react-tabs__tab::after {
  display: none;
}

.tabs_wrap .react-tabs__tab--selected {
  color: var(--whiteColor);
  background-color: var(--secondaryColor);
}

.tabs_wrap .react-tabs__tab-panel {
  background-color: var(--Color1);
  border-radius: 10px 10px 0 0;
  padding: 20px;
  -webkit-box-shadow: 0px 57px 52px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 57px 52px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 57px 52px 0px rgba(0, 0, 0, 0.15);
}

.tabs_wrap .add-return-btn {
  border-radius: 0px;
  background-color: var(--secondaryColor);
  color: var(--whiteColor);
  transition: all .6s ease;
  font-weight: 600;
  width: 100%;
}

.tabs_wrap .add-return-btn:hover {
  background-color: var(--mainColor);
  color: var(--secondaryColor);
}

.tabs_wrap .add-return-btn:hover img {
  filter: brightness(0);
}
.add-return1-btn {
  border-radius: 0px;
  background-color: var(--secondaryColor) !important;
  color: var(--whiteColor);
  transition: all .6s ease;
  font-weight: 600;
  width: 100%;
}

.add-return1-btn:hover {
  background-color: var(--secondaryColor);
  color: var(--whiteColor);
}

.add-return1-btn:hover img {
  filter: brightness(0);
}

@media(max-width:1099px) {
  .tabs_wrap .react-tabs__tab-list {
    scroll-behavior: smooth;
    white-space: nowrap;
    gap: 20px;
    flex-shrink: 0;
    display: flex;
    overflow-x: scroll;
  }

  /* .tabs_wrap ul {
    margin: -85px 0 0 0;
  } */

  /* .tabs_wrap .react-tabs__tab-panel {
    border-radius: 0 0 15px 15px;
  } */
}

@media(max-width:1099px) {
  .tabs_wrap .add-return-btn {
    /* max-width: 50% !important;
    min-width: 50% !important; */
    min-height: 50px !important;
    max-height: 50px !important;
    justify-content: center;
  }
}





.tabs_wrap_result .form-label {
  font-size: 12px;
  color: var(--pragraphColor);
  margin-bottom: 0;
}

.tabs_wrap_result .form-control {
  border: none;
  font-size: 18px;
  padding-left: 0px;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: var(--secondaryColor);
  font-weight: 500;
  background-image: none;
}

.tabs_wrap_result .css-13cymwt-control {
  border: none;
  font-size: 16px;
  padding-left: 0px;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: var(--secondaryColor) !important;
  font-weight: 500;
  background-image: none;
}

.tabs_wrap_result .css-t3ipsp-control:hover {
  border-color: none;
}

.tabs_wrap_result .form-control:focus,
.tabs_wrap_result .css-13cymwt-control:focus {
  box-shadow: none !important;
}

.tabs_wrap_result .form-select {
  border: none;
  font-size: 18px;
  padding-left: 0px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  color: var(--secondaryColor);
  font-weight: 500;
  background-image: none;
}

.tabs_wrap_result .form-select:focus {
  box-shadow: none;
}


.tabs_wrap_result .add-return-btn {
  border-radius: 0px;
  background-color: var(--secondaryColor);
  color: var(--whiteColor);
  transition: all .6s ease;
  font-weight: 600;
  width: 100%;
}

.tabs_wrap_result .add-return-btn:hover {
  background-color: var(--mainColor);
  color: var(--secondaryColor);
}

.tabs_wrap_result .add-return-btn:hover img {
  filter: brightness(0);
}


@media(max-width:1099px) {
  .tabs_wrap_result .add-return-btn {
    /* max-width: 50% !important;
    min-width: 50% !important; */
    min-height: 50px !important;
    max-height: 50px !important;
    justify-content: center;
  }
}

/*  */
.tabUL{
  margin: -85px 0 0 0;
}
.tabUL .fgrf_btn{
  color: var(--secondaryColor);
  font-family: var(--FontFamily_Figtree);
  font-size: 16px;
  font-weight: 500;
  border-radius: 100px;
  border: none;
  width: 100%;
  max-width: 150px;
  min-width: 150px;
}

.tabUL .fgrf_btn.selected{
  background-color: var(--secondaryColor);
  color: var(--Color1);
}
@media(max-width:991px){
  .tabUL .fgrf_btn{
      max-width: 130px;
      min-width: 130px;
  }
}
@media(max-width:1099px) {
  .tabUL {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
.modal-open {
  padding-right: 0 !important;
}

/* heading */
.heading h3,
.heading h4,
.heading h5,
.heading h6 {
  font-size: 45px;
  color: var(--whiteColor);
  font-family: var(--FontFamily_ProstoOne);
  font-weight: 400;
  text-transform: uppercase;
  line-height: 45px;
}

@media(max-width:1199px) {

  .heading h3,
  .heading h4,
  .heading h5,
  .heading h6 {
    font-size: 32px;
  }
}

@media(max-width:991px) {

  .heading h3,
  .heading h4,
  .heading h5,
  .heading h6 {
    font-size: 30px;
    line-height: 30px;
  }
}

@media(max-width:768px) {

  .heading h3,
  .heading h4,
  .heading h5,
  .heading h6 {
    font-size: 22px;
    line-height: 28px;
  }
}

/* headeroffcanvas */
.headeroffcanvas {
  width: 400px !important;
}

.headeroffcanvas .offcanvas-header {
  background: var(--whiteColor);
  position: relative;
  padding: 15px 1rem;
  margin-bottom: 20px;
  border-top: solid 3px var(--mainColor);
  border-bottom: solid 1px var(--darkgreenColor);
}

.headeroffcanvas .offcanvas-header .btn-close {
  background-color: var(--whiteColor);
  border-radius: 100px;
  padding: 10px;
}

.headeroffcanvas .offcanvas-header .btn-close:hover,
.headeroffcanvas .offcanvas-header .btn-close:focus {
  background-color: var(--pragraphColor);
}

.headeroffcanvas .offcanvas-header h4,
.headeroffcanvas .offcanvas-header h5,
.headeroffcanvas .offcanvas-header h6 {
  font-size: 16px;
  font-weight: 500;
  color: var(--blackColor);
}

@media(max-width:575px) {
  .headeroffcanvas {
    width: 250px !important;
  }
}


/*  */
.resultPageTopRow .form-label {
  font-size: 12px;
  color: var(--pragraphColor);
  margin-bottom: 0;
}

.resultPageTopRow .form-control {
  border: none;
  font-size: 18px;
  padding-left: 0px;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: var(--secondaryColor);
  font-weight: 500;
  background-image: none;
}

.resultPageTopRow .form-control:focus {
  box-shadow: none;
}

.resultPageTopRow .form-select {
  border: none;
  font-size: 18px;
  padding-left: 0px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  color: var(--secondaryColor);
  font-weight: 500;
  background-image: none;
}

.resultPageTopRow .form-select:focus {
  box-shadow: none;
}

.resultPageTopRow .modify-btn {
  border-radius: 0px;
  background-color: var(--secondaryColor);
  color: var(--mainColor);
  transition: all .6s ease;
  font-weight: 600;
}

.resultPageTopRow .modify-btn:hover {
  background-color: var(--mainColor);
  color: var(--secondaryColor);
}

@media(max-width:575px) {
  .resultPageTopRow .modify-btn {
    max-height: 50px !important;
    min-height: 50px !important;
  }
}

/* footer */
.footer {
  background: var(--secondaryColor);
  border-radius: 35px 35px 0px 0px;
  color: var(--pragraphColor);
  text-align: left;
}

.footer .title {
  color: var(--mainColor);
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.footer ul li {
  font-size: 14px;
  line-height: 30px;
}

.footer ul li a {
  color: var(--whiteColor);
  font-weight: 400;
  transition: all .6s ease;
  font-family: var(--FontFamily_Figtree);
}

.footer ul li a:hover {
  color: var(--mainColor);
}


.loginbtn {
  background: var(--secondaryColor);
  border-radius: 36px;
  border: none;
  padding: 0;
  height: 54px;
  color: var(--whiteColor);
  font-weight: bold;
}

.loginbtn:hover {
  background: var(--mainColor);
  color: var(--blackColor);

}

.signUpbtn {
  background: var(--whiteColor);
  border-radius: 36px;
  border: solid 2px var(--secondaryColor);
  padding: 0;
  height: 54px;
  color: var(--secondaryColor);
  font-weight: bold;
}

.signUpbtn:hover {
  background: var(--secondaryColor);
  color: var(--whiteColor);

}

.email-icon {
  padding-left: 38px !important;
  background: url("./images/email.png") no-repeat left;
  background-size: 20px;
  background-position: 10px center;
}

.user-icon {
  padding-left: 38px !important;
  background: url("./images/usericon.png") no-repeat left;
  background-size: 20px;
  background-position: 10px center;
}

.password-icon {
  padding-left: 38px !important;
  background: url("./images/password.png") no-repeat left;
  background-size: 20px;
  background-position: 10px center;
}

.loginform input {
  background-color: var(--Color1);
  border: none;
  height: 50px;
  display: grid;
  align-items: center;
}

.loginform input:focus {
  background-color: var(--Color1);
  border: none;
  padding: 9px;
  outline: 0;
  box-shadow: 0 0 0 0.06rem rgb(24 34 57);
}

a {
  color: #787878;
  text-decoration: none !important;
}

.orlogin {
  position: relative;
  display: grid;
  align-items: center;
}

.orlogin::before {
  position: absolute;
  content: "";
  width: 35%;
  height: 1.5px;
  z-index: 0;
  left: 0;
  background-color: #182239;
}

.orlogin::after {
  position: absolute;
  content: "";
  width: 35%;
  height: 1.5px;
  z-index: 0;
  right: 0;
  background-color: #182239;
}
.orlogin span{
  width: 120px;
  background: #fff;
  position: relative;
  margin: 0 auto;
  z-index: 1;
}
.login-card-bg::after {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  content: "";
  background-image: linear-gradient(to top, rgba(255, 0, 0, 0), rgb(22, 34, 56));
  position: absolute;
}


/* profileImg */
.profileImg {
  width: 100%;
  height: 100%;
  max-width: 80px;
  min-width: 80px;
  min-height: 80px;
  max-height: 80px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* breadcrumb_wrap */
.breadcrumb_wrap {
  position: relative;
  min-height: 300px;
  display: grid;
  margin-bottom: 15px;
  align-items: center;
  border-radius: 0 0 30px 30px;
  background-size: 100%;
  background-image: url(./images/banner_01.jpg);
  background-position: center center;
  background-repeat: no-repeat;
}

.breadcrumb_wrap::before {
  content: '';
  position: absolute;
  width: 100%;
  border-radius: 0 0 30px 30px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.35);
}

.breadcrumb {
  margin: 0 !important;
  justify-content: center;
}

.breadcrumb .breadcrumb-item {
  color: var(--whiteColor);
}

.breadcrumb .breadcrumb-item a {
  color: var(--whiteColor);
}

.breadcrumb .breadcrumb-item::before {
  color: var(--whiteColor);
}

/*----------------feel_wrap-----------------*/
.feel_wrap {
  position: relative;
  background-image: url(./images/bg-5.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 48px;
  /* background-attachment: fixed; */
  background-size: cover;
  overflow: hidden;
}

.feel_wrap.bg_1 {
  background-image: url(./images/bg-9.jpg);
  background-attachment: fixed;
  background-position: bottom center;
  min-height: 400px;
  display: grid;
  align-items: center;
}

/* .feel_wrap.bg_1::after {
  background: rgb(82, 76, 117);
  background: linear-gradient(90deg, rgba(82, 76, 117, 1) 0%, rgba(22, 34, 56, 1) 50%, rgba(13, 33, 12, 0) 100%);
} */

.feel_wrap::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: rgb(22, 34, 56);
  background: linear-gradient(90deg, rgba(22, 34, 56, 1) 0%, rgba(82, 76, 117, 0.15730042016806722) 50%);
}

.feel_wrap h4.title {
  color: var(--whiteColor);
  font-family: var(--FontFamily_ProstoOne);
  font-size: 48px;
  line-height: 59.28px;
  font-weight: 500;
}

.feel_wrap .registerNow-btn {
  background-color: var(--mainColor);
  border-radius: 100px;
  width: 100%;
  height: 100%;
  max-width: 200px;
  min-width: 200px;
  min-height: 50px;
  max-height: 50px;
  font-size: 16px;
  color: var(--secondaryColor);
  transition: all .6s ease;
  font-weight: 700;
  border: solid 2px transparent;
  -webkit-box-shadow: 2px 11px 24px 0px rgba(255, 212, 118, 0.15);
  -moz-box-shadow: 2px 11px 24px 0px rgba(255, 212, 118, 0.15);
  box-shadow: 2px 11px 24px 0px rgba(255, 212, 118, 0.15);
}

.feel_wrap .registerNow-btn:hover {
  background-color: transparent;
  color: var(--mainColor);
  border-color: var(--mainColor);
}

@media(max-width:1199px) {
  .feel_wrap h4.title {
    font-size: 36px;
    line-height: 40px;
  }
}

@media(max-width:991px) {
  .feel_wrap h4.title {
    font-size: 26px;
    line-height: 30px;
  }
}

/*----------------SendEmail-btn-----------------*/
.SendEmail-btn {
  background-color: var(--mainColor);
  border-radius: 100px;
  width: 100%;
  height: 100%;
  max-width: 150px;
  min-width: 150px;
  min-height: 50px;
  max-height: 50px;
  font-size: 16px;
  color: var(--secondaryColor);
  transition: all .6s ease;
  font-weight: 700;
  border: solid 2px transparent;
  -webkit-box-shadow: 2px 11px 24px 0px rgba(255, 212, 118, 0.15);
  -moz-box-shadow: 2px 11px 24px 0px rgba(255, 212, 118, 0.15);
  box-shadow: 2px 11px 24px 0px rgba(255, 212, 118, 0.15);
}

.SendEmail-btn:hover {
  background-color: transparent;
  color: var(--mainColor);
  border-color: var(--mainColor);
}

@media(max-width:575px) {
  .SendEmail-btn {
    font-size: 14px;
    max-width: 130px !important;
    min-width: 130px !important;
    min-height: 40px !important;
    max-height: 40px !important;
  }

  .StartChat-btn {
    font-size: 14px !important;
    max-width: 130px !important;
    min-width: 130px !important;
    min-height: 40px !important;
    max-height: 40px !important;
  }
}

/*----------------StartChat-btn-----------------*/
.StartChat-btn {
  background-color: var(--whiteColor);
  border-radius: 100px;
  width: 100%;
  height: 100%;
  max-width: 150px;
  min-width: 150px;
  min-height: 50px;
  max-height: 50px;
  font-size: 16px;
  color: var(--secondaryColor);
  transition: all .6s ease;
  font-weight: 700;
  border: solid 2px transparent;
  -webkit-box-shadow: 2px 11px 24px 0px rgba(255, 212, 118, 0.15);
  -moz-box-shadow: 2px 11px 24px 0px rgba(255, 212, 118, 0.15);
  box-shadow: 2px 11px 24px 0px rgba(255, 212, 118, 0.15);
}

.StartChat-btn:hover {
  background-color: transparent;
  color: var(--mainColor);
  border-color: var(--mainColor);
}

.StartChat-btn:hover img {
  filter: brightness(100);
}

/*----------------bgImage-----------------*/
.bgImage {
  position: relative;
}

.bgImage::after {
  content: '';
  position: absolute;
  top: -200px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-image: url(./images/bg.png);
  background-repeat: no-repeat;
  background-position: center top;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/*----------------bgImage1-----------------*/
.bgImage1 {
  position: relative;
}

.bgImage1::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-image: url(./images/bg-11.png);
  background-repeat: no-repeat;
  background-position: center top;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/*----------------destinations-----------------*/
.destinations {
  background-image: url(./images/bg-4.png), url(./images/bg-3.png);
  background-position: left bottom, top right;
  background-repeat: no-repeat, no-repeat;
  padding-bottom: 60px;
}

.destinations .card.boxCard {
  border: none;
  padding: 0;
  background-color: transparent;
}

.destinations .card.boxCard .card-header {
  border-radius: 10px;
  padding: 0;
  background-color: transparent;
  margin-bottom: 0;
  border: none;
  overflow: hidden;
  /* width: 100%;
  height: 100%; */
  /* max-width: 300px;
  min-width: 300px;
  max-height: 285px;
  min-height: 285px; */
  /* margin: 0 auto; */
}
@media(max-width:768px) {
  .destinations .card.boxCard .helloImg {
    width: 100% !important;
  }
}


/* All Destination Page */
.alldestinations {
  /* background-image: url(./images/bg-4.png), url(./images/bg-3.png); */
  /* background-position: left bottom, top right; */
  /* background-repeat: no-repeat, no-repeat; */
  padding-bottom: 60px;
}
.alldestinations .card.boxCard {
  border: none;
  padding: 0;
  background-color: transparent;
  transition: box-shadow 0.6s ease, transform 0.6s ease;
}
.alldestinations .card.boxCard:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}
.alldestinations .card.boxCard {
  border-radius: 10px;
  padding: 0;
  background-color: transparent;
  margin-bottom: 0;
  border: none;
  overflow: hidden;
  /* width: 100%;
  height: 100%; */
  /* max-width: 300px;
  min-width: 300px;
  max-height: 285px;
  min-height: 285px; */
  /* margin: 0 auto; */
}
.alldestinations .card.boxCard .helloImg {
  position: relative !important;
}
.alldestinations .card.boxCard .card-body::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgb(0 0 0 / 57%);
  z-index: 0;
}
@media(max-width:768px) {
  .alldestinations .card.boxCard .helloImg {
    width: 100% !important;
  }
}

.card-body .title {
  font-size: 20px;
  line-height: 28.8px;
  font-weight: 600;
}

/* destinations_tabs */
.destinations_tabs .cardButton .react-tabs__tab-list {
  display: flex;
  justify-content: inherit;
  border: none;
  margin-bottom: none;
  gap: 20px;
}

.destinations_tabs .cardButton .react-tabs__tab::after {
  display: none;
}

.destinations_tabs .cardButton .react-tabs__tab::before {
  display: none;
}

.destinations_tabs .cardButton .react-tabs__tab {
  border-radius: 8px;
}

.destinations_tabs .cardButton .react-tabs__tab--selected {
  transform: none;
  background-color: #E6EAF0;
}

.destinations_tabs .cardButton .react-tabs__tab .btn:active,
.destinations_tabs .cardButton .react-tabs__tab .btn:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.destinations_tabs .cardButton .react-tabs__tab:active,
.destinations_tabs .cardButton .react-tabs__tab:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

/* destinations_tabs */
.destinations_tabs .react-tabs__tab-list {
  display: flex;
  justify-content: space-between;
  border-bottom: dashed 2px var(--pragraphColor);
}

.destinations_tabs .react-tabs__tab {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 15px;
  font-weight: 600;
  color: var(--darkgreenColor);
  border-radius: 0;
  border-bottom-width: 2px;
  transition: all .6s ease;

}

.destinations_tabs .react-tabs__tab:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.destinations_tabs .react-tabs__tab::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -15px;
  background: transparent;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top-color: var(--darkgreenColor);
}

.destinations_tabs .react-tabs__tab::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 41px;
  width: 0;
  height: 0;
  background: transparent;
  border: 8px solid transparent;
  border-bottom-color: var(--darkgreenColor);
}

.destinations_tabs .react-tabs__tab--selected {
  border-left: none;
  border-top: none;
  border-right: none;
  /* border-color: var(--darkgreenColor);
  background-color: transparent; */
  transform: scale(1.1);
  background-color: var(--darkgreenColor);
  border-radius: 11px 11px 0 0;
  color: #fff;
}

.destinations_tabs .react-tabs__tab--selected::before {
  top: 42px;
}

.destinations .destinations-btn {
  background-color: var(--secondaryColor);
  border-radius: 100px;
  width: 100%;
  height: 100%;
  max-width: 255px;
  min-width: 255px;
  min-height: 50px;
  max-height: 50px;
  font-size: 16px;
  color: var(--mainColor);
  transition: all .6s ease;
  font-weight: 700;
  -webkit-box-shadow: 2px 11px 24px 0px rgba(22, 34, 56, 0.15);
  -moz-box-shadow: 2px 11px 24px 0px rgba(22, 34, 56, 0.15);
  box-shadow: 2px 11px 24px 0px rgba(22, 34, 56, 0.15);
}

.destinations .destinations-btn:hover {
  background-color: var(--mainColor);
  color: var(--secondaryColor);
}

@media(max-width:1140px) {
  .destinations_tabs .react-tabs__tab-list {
    display: flex;
    overflow-x: scroll;
    gap: 30px;
    overflow-y: hidden;
  }

  .destinations_tabs .react-tabs__tab {
    white-space: nowrap;
    font-size: 14px;
  }

  .destinations_tabs .react-tabs__tab::after,
  .destinations_tabs .react-tabs__tab::before {
    display: none;
  }
}

@media(max-width:1199px) {
  .destinations {
    background-image: none;
  }
}

/*-----------------------accountTabs----------------------*/
.accountTabs .react-tabs__tab-list {
  display: flex;
  justify-content: space-between;
  border: none;
  margin-bottom: 40px;
  gap: 20px;
  border-radius: 100px 100px 100px 100px;
  overflow: hidden;
  background-color: var(--whiteColor);
  height: 56px;
}

.accountTabs .react-tabs__tab::after,
.accountTabs .react-tabs__tab::before {
  display: none;
}

.accountTabs .react-tabs__tab {
  border: none;
  width: 100%;
  border-radius: 0;
  display: flex;
  transition: all .6s ease;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
  font-weight: 500;
}

.accountTabs .react-tabs__tab--selected {
  transform: none;
  background-color: var(--secondaryColor);
  color: var(--whiteColor);
}

.accountTabs .react-tabs__tab--selected img {
  filter: brightness(100);
}

.accountTabs .react-tabs__tab .btn:active,
.accountTabs .react-tabs__tab .btn:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.accountTabs .react-tabs__tab:active,
.accountTabs .react-tabs__tab:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.accountTabs .form-control,
.accountTabs .form-select {
  border-radius: 8px;
  background-color: var(--Color1);
  height: 50px;
  border: none;
}

.accountTabs .form-control:focus,
.accountTabs .form-select:focus {
  box-shadow: none;
  border: none;
}

.accountTabs .css-14t08it-control {
  border: none;
  background-color: var(--Color1);
  height: 50px;
  border-radius: 8px;
  color: var(--bs-body-color);
  font-size: 16px;
}

@media(max-width:991px) {
  .accountTabs .react-tabs__tab-list {
    scroll-behavior: smooth;
    white-space: nowrap;
    overflow-x: auto;
    gap: 10px;
    border-radius: 0;
  }

  .accountTabs .react-tabs__tab {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/*-----------------------tripTabs----------------------*/
/* .tableContent.table:not(caption)>*>* {
  border-bottom-width: none;
} */

.tableContent.table thead,
tbody,
tfoot,
tr,
td,
th {
  padding: 20px;
  font-size: 14px;
  color: var(--pragraphColor);
  font-weight: 500;
}

.tableContent.table thead th {
  background-color: var(--secondaryColor);
  color: var(--whiteColor);
  padding: 15px 30px;
}

/*-----------------------tripTabs----------------------*/
.tripTabs .react-tabs__tab-list {
  margin-bottom: 0;
  gap: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    justify-content: normal;
  padding-left: 30px;
  padding-right: 30px;
}

.tripTabs .react-tabs__tab {
  padding-left: 30px;
  padding-right: 30px;
}

.tripTabs .react-tabs__tab::after {
  content: '';
  position: absolute;
  bottom: 0;
  display: none;
  height: 2px;
  background-color: var(--secondaryColor);
}

.tripTabs .react-tabs__tab--selected {
  background-color: var(--darkgreenColor);
  color: var(--Color1);
  font-weight: 500;
}

/*-----------------------modal----------------------*/
.modal::after {
  content: '';
  position: fixed;
  height: 100%;
  width: 100%;
  background: var(--secondaryColor);
  opacity: 0.3;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal .btn-close {
  color: var(--white_color);
  font-size: 18px !important;
  background-image: url(./images/download.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-color: var(--secondaryColor);
  opacity: 1;
  width: 33px;
  border-radius: 6px;
  padding: 0;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
}

.modal .modal-content {
  padding: 25px;
  border-radius: 30px;
}

.modal .modal-header {
  border: none;
  background: var(--whiteColor);
  padding: 0;
}

.modal .modal-header .modal-title.h4 {
  color: var(--secondaryColor);
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  font-family: var(--FontFamily_Figtree);
}

.modal .close {
  opacity: 1;
  font-size: 2.1rem;
}
@media (max-width:991px) {
  .modal .modal-header .modal-title.h4 {
    font-size: 22px;
    line-height: 26px;
  }
}
/*----------------back-to-top-----------------*/

.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 44px;
  height: 44px;
  color: #fff;
  background: var(--mainColor);
  text-align: center;
  line-height: 44px;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
  -webkit-box-shadow: 4px 3px 24px rgba(101, 177, 91, 1);
  box-shadow: 4px 3px 24px rgba(101, 177, 91, 1);
  display: none;
  -webkit-animation: backto-top-bounce 4s infinite ease-in-out;
  animation: backto-top-bounce 4s infinite ease-in-out;
  border-radius: 4px;
}

.back-to-top .back-top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: url(./images/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transform: rotate(180deg);
}

@-webkit-keyframes backto-top-bounce {
  0% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }

  50% {
    -webkit-transform: translateY(8px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
}

@keyframes backto-top-bounce {
  0% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }

  50% {
    -webkit-transform: translateY(8px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
}


/*-----------------------webkit-scrollbar----------------------*/

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  border-radius: 10px;
  background-color: transparent;
  border-left: 0 solid transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--mainColor);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--mainColor);
  opacity: 1;
}


.bouncing-loader {
  display: flex;
  justify-content: center;
  margin: 26px auto;
}

.bouncing-loader>div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: var(--mainColor);
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader>div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader>div:nth-child(3) {
  animation-delay: 0.4s;
}

.btn:disabled {
  opacity: 0.6;
  background-color: var(--blackColor)
}

.react-tel-input .form-control {
  position: relative;
  font-size: 1rem;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: var(--Color1);
  border: none;
  border-radius: 5px;
  line-height: 25px;
  height: 50px;
  width: 100%;
  outline: none;
  display: grid;
  align-items: center;
}

.react-tel-input input:focus {
  padding-left: 48px;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: var(--Color1);
  border: none;
  border-radius: 3px 0 0 3px;
}

/* User Profile */
.userprofile {
  width: 100%;
  height: 100%;
  max-width: 34px;
  min-width: 34px;
  max-height: 34px;
  min-height: 34px;
  border-radius: 100px;
}

/* User Profile1 */
.userprofile1 {
  width: 100%;
  height: 100%;
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
  min-height: 100px;
  border-radius: 100px;
  overflow: hidden;
}

.userprofile1 img {
  width: 100%;
  object-fit: cover;
}

/* Loader */

@media(min-width:1400px) {
  .loader-gif {
    height: 500px;
    width: 500px;
    margin: auto;
  }
}

/* Toggle Button */
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 62px;
  height: 27.56px;
  padding: 0;
  border-radius: 30px;
  background-color: #e5e5e5;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}



.react-toggle--checked .react-toggle-track {
  background-color: var(--secondaryColor);
}



.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 3px;
  left: 4px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #4D4D4D;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 37px;
  border-color: var(--mainColor);
  background-color: var(--mainColor);
}
.font-bold {
  font-weight: 700;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.ml-4 {
  margin-left: 1rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: var(--blackColor);
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--blackColor);
}
.aye-hay-oye-hoye{
  border: none;
  background-color: var(--Color1);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 0;
  -webkit-box-shadow: 0px 57px 52px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 57px 52px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 57px 52px 0px rgba(0, 0, 0, 0.15);
}
@media(max-width:991px){
  .aye-hay-oye-hoye{ 
    border-radius:0 0 20px 20px;
  }
}
/* @media screen and (min-device-width: 1200px) and (max-device-width: 1440px) {
  .aye-hay-oye-hoye {
    padding: 0;
  }
} */
.autosuggest {
  position: relative;
}
.suggestions-list {
  position: absolute;
  top: 57px;
  left: 6px;
  right: 0;
  width: 395px;
  max-height: 300px;
  overflow-y: auto;
  background: white;
  border-radius: 12px;
  border-top: none;
  z-index: 1000;
  text-align: left;
  padding: 10px;
  box-shadow: 0px 57px 52px 0px rgba(0, 0, 0, 0.15);
}
.suggestions-list .suggestions-li:hover{
    background-color: #f5f5f5;
}
.suggestions-list .suggestions-li{
  padding: 10px;
}
.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0 !important;
}

.ant-picker-outlined {
  background: #ffffff !important;
  border-width: none !important;
  border-style: none !important;
  border-color: none !important;
}
.ant-picker{
  width: 100% !important;
  font-size: 18px !important;
  padding:0px !important;
}
.ant-picker .ant-picker-input >input{
  font-size: 16px !important;
}
.ant-picker-outlined:focus, .ant-picker-outlined:focus-within {
  border-color: transparent !important;
  box-shadow: none !important;
  outline: 0 !important;
  background-color: transparent !important;
}
.ant-btn-primary {
  color: #fff !important;
  background: var(--mainColor) !important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1) !important;
  width: 108px !important;
  height: 31px !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-1uq9j6g).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-1uq9j6g).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner{
  background: var(--mainColor) !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border: 1px solid var(--mainColor) !important;
}






/* .ant-picker-time-panel {
  position: absolute;
  bottom: 0; /* Position at the bottom */
/* }  */

.custom-time-panel {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}


.btn-tab-footer{
  background: var(--mainColor);
    border: 0;
    margin: 2px;
    outline: none;
    font-weight: 600;
    border-radius: 5px;
    color: var(--blackColor);
    font-size: 12px;
    padding: 0 12px;
}

.btn-tab-footer:hover{
  background-color: var(--Color1);
}

.btn-tab-footer.active{
  background-color: var(--Color1);
}
.sectiontext p{
  color: var(--Color1);
}
.description p{
  text-align: justify;
}


blockquote {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}

.trhover:hover{
  border: 8px solid var(--mainColor)
}


::selection{
  background-color: var(--blackColor);
}

.tableContent th{
  padding: 0.5rem 0.5rem !important;
}
.tableContent tr{
  padding-bottom: 40px !important;
  position: relative;
}

.tableContent tr:hover{
  border-top: 2px solid var(--mainColor);
  border-bottom: 2px solid var(--mainColor);
}
.tableContent .groupingbtn{ 
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
}
.tableContent tr:hover .groupingbtn{ 
  display: flex !important;
}

.tableContent tr:hover.spaceUnder>td {
  padding-bottom: 55px;
}

/* myJob */
.myJob{
  padding-bottom: 70px;
  padding-top: 40px;
  border-radius: 0 0 100px 100px;
}
.myJob .card{
  margin-top: -50px;
}
.myJob .card .title{
  font-size: 18px;
  font-weight: 600;
  color: var(--secondaryColor);
  line-height: 20px;
}
.myJob .card .profilePicture{
  width: 100%;
  height: 100%;
  max-width: 55px;
  min-width: 55px;
  max-height: 55px;
  min-height: 55px;
  border: 100%;
  overflow: hidden;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .6s;
  border: solid 2px transparent;
  background-color: var(--secondaryColor);
}
.myJob .card .profilePicture:hover{
  border-color: var(--mainColor);
}

.designLine .circleIcon::after{
  content: '';
    position: absolute;
    height: 90%;
    top: 0;
    right: 0;
    margin: 0 auto;
    left: 30px;
    z-index: 0;
    border: dashed 2px var(--secondaryColor);
    border-top: none;
    border-bottom: none;
    border-right: none;
}

/*  */
.slide-but{
  border-radius: 100px !important;
  background-color: var(--secondaryColor) !important;
  color: var(--whiteColor) !important;
  font-weight: 600 !important;
  border: solid 2px var(--mainColor) !important;
}
.slide-but .slide-overlay{
  width: 100% !important;
  background:transparent !important;
  /* max-width: 55px !important;
  min-width: 55px; */
}
.slide-but .slide-overlay .slide-caret-wrapper{ 
  background-color: transparent !important;
  left: 4px;
  padding: 0;
  top: 50%;
}
.slide-but .slide-overlay .slide-overlay-txt{
  background: var(--whiteColor);
  border-radius: 100px;
  width: 55px;
}

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, .3);
}

.modal.finish_trip_modal{
  width: 300px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
}


.driver-profile-form .form-control{
  border-radius: 8px;
  height: 55px;
  background-color: #F3F3F3;
  border-color: none;
  box-shadow: inset #00000029 0 0 4px 1px;
}
.driver-profile-form .form-select{
  border-radius: 8px;
  height: 55px;
  background-color: #F3F3F3;
  border-color: none;
  box-shadow: inset #00000029 0 0 4px 1px;
}
.exchangerbtn{
  height: 100%;
}
@media (max-width:768px) {
  .exchangerbtn{
    height: auto;
    top: 40%;
  }
}

.tawk-button{
    width: 87px !important;
    height: 33px !important;
}
.tawk-text-bold-3 {
  font-size: 14px !important;
}

/* ===============contentAgency=============== */
.contentAgency .titleNumber{
  font-size: 48px;
  line-height: normal;
  background-color: var(--whiteColor);
  font-weight: 700;
  color: var(--secondaryColor);
}
.contentAgency .titleNumber::after{
  content: '';
  position: absolute;
  height: 100%;
  top: 20px;
  left: calc(100% - 96%);
  z-index: -1;
  opacity: 0.5;
  right: auto;
  margin: 0 auto;
  border-left:dashed 2px var(--pragraphColor);
}
.contentAgency .title{
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  color: var(--secondaryColor);
}
@media(max-width:991px){
  .contentAgency .titleNumber{
    font-size: 36px;
  }
  .contentAgency .title{
    font-size: 20px;
  }
}
/* ===============contentCityTour=============== */
.contentCityTour .titleNumber{
  font-size: 48px;
  line-height: normal;
  font-weight: 700;
  color: var(--secondaryColor);
}
.contentCityTour .titleNumber::after{
  content: '';
  position: absolute;
  height: 100%;
  top: 54px;
  left: calc(100% - 96%);
  opacity: 0.5;
  right: auto;
  margin: 0 auto;
  border-left:dashed 2px var(--pragraphColor);
}
.contentCityTour .title {
  font-size: 19px;
  text-align: left;
  font-weight: 600;
  line-height: normal;
  color: var(--secondaryColor);
}
.contentCityTour h2 {
  font-size: 32px;
  color: var(--secondaryColor);
  font-family: var(--FontFamily_ProstoOne);
  font-weight: 400;
  text-transform: uppercase;
  line-height: 45px;
}
@media(max-width:991px){
  .contentCityTour .titleNumber{
    font-size: 36px;
  }
  .contentCityTour .title{
    font-size: 20px;
  }
  .contentCityTour h2 {
    font-size: 22px;
  }
}

/* slider */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 60%;
}

.swiper-slide:nth-child(2n) {
  width: 60%;
}

.swiper-slide:nth-child(3n) {
  width: 60%;
}

.swiper-pagination {
  position: relative !important;
  text-align: center !important;
  margin-top: 17px !important;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #162238;
}